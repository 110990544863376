import React, { Fragment } from 'react';
import { UserOutlined, PhoneOutlined, MailOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { Row, Col, List, Avatar, Form, Input, Button, message, Image, Space } from 'antd';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { buildInfoData } from '../../helpers';
import logo from '../../assets/logo.svg';

const { Item: ListItem } = List;
const { TextArea } = Input;

const Home = (props) => {
  const [form] = Form.useForm();
  const { i18n } = props;
  const [saving, setSaving] = React.useState(false);

  const handleSubmit = (values) => {
    if (!saving) {
      setSaving(true);
      fetch('mailer.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      })
      .then(() => {
        setSaving(false);
        message.success(i18n.t('home.sendedMsg'), 5);
        form.resetFields();
      })
      .catch(() => {
        setSaving(false);
        message.error(i18n.t('home.errorMsg'), 5);
      });
    }
  }

  return (
    <Fragment>
      <header id="header">
        <div className="intro">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 intro-text">
                  <img alt="" src={logo} />
                  <p>
                    {i18n.t('home.mainMessage')}
                  </p>
                  <AnchorLink href="#info" className="btn btn-custom btn-lg page-scroll">{i18n.t('home.more')}</AnchorLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div id="info" className="text-center">
        <div className="container">
          <Row gutter={16} className="section-title">
            <Col>
              <h2 style={{ textAlign: 'center' }} >{i18n.t('home.didYouKnow')}</h2>
              <p>{i18n.t('home.infoDesc')}</p>
            </Col>
          </Row>
          <Row gutter={16} type="flex" justify="center" >
            <Col xs={24} md={18} >
              <List
                itemLayout="horizontal"
                dataSource={buildInfoData(i18n)}
                renderItem={item => (
                  <ListItem>
                    <ListItem.Meta
                      avatar={<Avatar src={item.icon} size="large" shape="square" />}
                      title={<b>{item.title}</b>}
                      description={item.description}
                    />
                  </ListItem>
                )}
              />
            </Col>
          </Row>
        </div>
      </div>
      <div id="about">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6"> <img src="img/about.jpg" className="img-responsive" alt="" /></div>
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <h2>{i18n.t('home.aboutTitle')}</h2>
                <p>{i18n.t('home.aboutDesc')}</p>
                <h3>{i18n.t('home.whyUs')}</h3>
                <p>{i18n.t('home.whyUsDesc')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="catalog" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>{i18n.t('home.productsTitle')}</h2>
          </div>
          <Row >
            <Col>
              <Image.PreviewGroup preview={{
                toolbarRender: (_, { transform: { scale }, actions: { onZoomIn,  onZoomOut } }) =>
                  <Space size={20} className="toolbar-wrapper">
                    <ZoomOutOutlined size={100} disabled={scale === 1} onClick={onZoomOut} />
                    <ZoomInOutlined size={100} disabled={scale === 50} onClick={onZoomIn} />
                  </Space>,
              }}>
                <Image height={350} width='auto' src='/img/product_1.jpg' />
                <Image height={350} width='auto' src='/img/product_2.jpg' />
                <Image height={350} width='auto' src='/img/product_3.jpg' />
                <Image height={350} width='auto' src='/img/product_4.jpg' />
                <Image height={350} width='auto' src='/img/product_5.jpg' />
                <Image height={350} width='auto' src='/img/product_6.jpg' />
                <Image height={350} width='auto' src='/img/product_7.jpg' />
                <Image height={350} width='auto' src='/img/product_8.jpg' />
                <Image height={350} width='auto' src='/img/product_9.jpg' />
                <Image height={350} width='auto' src='/img/product_10.jpg' />
                <Image height={350} width='auto' src='/img/product_11.jpg' />
                <Image height={350} width='auto' src='/img/product_12.jpg' />
                <Image height={350} width='auto' src='/img/product_13.jpg' />
                <Image height={350} width='auto' src='/img/product_14.jpg' />
                <Image height={350} width='auto' src='/img/product_15.jpg' />
              </Image.PreviewGroup>
            </Col>
          </Row>
        </div>
      </div>
      <div id="contact" className="text-center" >
        <div className="container">
          <div className="section-title">
            <h2>{i18n.t('home.contactTitle')}</h2>
            <p>{i18n.t('home.contactDesc')}</p>
          </div>
          <Row gutter={16} type='flex' justify='center' >
            <Col xs={24} sm={16}>
              <Row gutter={16} >
                <Col>{i18n.t('home.email')}</Col>
              </Row>
              <Row gutter={16} >
                <Col>xocolati@infinitummail.com</Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={16} type="flex" justify="center" >
            <Col xs={24} sm={16} >
              <Form onFinish={handleSubmit} >
                <Row gutter={16} style={{ marginBottom: '-1.5rem' }} >
                  <Col xs={24}>
                    <Form.Item name='name' rules={[{ required: true, message: i18n.t('home.required') }]}>
                      <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={i18n.t('home.name')} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16} style={{ marginBottom: '-1.5rem' }} >
                  <Col xs={24}>
                    <Form.Item name='phone' rules={[{ required: true, message: i18n.t('home.required') }]}>
                      <Input prefix={<PhoneOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={i18n.t('home.phone')} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16} style={{ marginBottom: '-1rem' }} >
                  <Col xs={24}>
                    <Form.Item name='email' rules={[{ required: true, message: i18n.t('home.required') }]}>
                      <Input prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={i18n.t('home.email')} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16} >
                  <Col xs={24}>
                    <Form.Item name='message' rules={[{ required: true, message: i18n.t('home.required') }]}>
                      <TextArea rows={4} placeholder={i18n.t('home.message')} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16} type='flex' justify='center' >
                  <Col xs={24} sm={8}>
                    <Button block size='large' type='primary' loading={saving} htmlType="submit" >{i18n.t('home.send')}</Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  );
}

export default Home;
