export default {
  home: {
    home: 'Inicio',
    required: 'Campo requerido',
    name: 'Nombre',
    phone: 'Teléfono',
    email: 'e-mail',
    message: 'Mensaje',
    sendedMsg: 'Su mensaje fue enviado exitosamente',
    errorMsg: 'Lo sentimos, su mensage no pudo ser enviado',
    send: 'Enviar',
    more: 'Saber más',
    offers: 'Ofertas',
    artisian: 'Artesano',
    mainMessage: 'Chocolate orgánico de Tabasco, el mejor chocolate de México.',
    about: 'Acerca de',
    whyUs: '¿Porque elegirnos?',
    whyUsDesc: 'Productos de Cacao con ecosistema único, sabor particular, libre de modificaciones genéticas, sin uso de fertilizantes y pesticidas, fermentado y secado al sol de forma artesanal.',
    aboutTitle: 'El artesano fabricante de chocolate - xocolati®',
    aboutDesc: 'Somos una empresa productora y transformadora de derivados del Cacao, retomando las mejores prácticas milenarias en la totalidad del ciclo productivo, contribuyendo al desarrollo sustentable, deleite, bienestar, vida saludable, cultura y conocimiento.',
    productsTitle: 'Catálogo de productos',
    catalog: 'Catálogo',
    contact: 'Contacto',
    contactTitle: 'Mantengase en contacto',
    contactDesc: 'Sientase libre de enviarnos un email.',
    didYouKnow: '¿Sabías que?',
    infoDesc: `No por nada se le conoce como el alimento de los dioses, ya que los aztecas y mayas le rendían tributo por sus grandes propiedades nutricionales y por supuesto, su sabor.
    De la semilla del cacao se pueden extraer tres sustancias: licor de cacao, manteca de cacao y cocoa en polvo. Todos ellos se utilizan para hacer diferentes productos tanto comestibles como de belleza; cobertura de chocolate, postres de chocolate, pan, mole, jabones, cremas, shampoo, entre otros.
    De acuerdo con la nutrióloga Yareri Sandoval, coordinadora de nutrición de la Federación Mexicana de Diabetes, y la dermatóloga Rosa María Ponce, este alimento tiene varios beneficios:`,
    nutritionalTitle: 'Propiedades nutricionales',
    nutritional1: 'El cacao encabeza la lista de alimentos con mayor contenido de antioxidantes, casi similar al té verde y vino tinto.',
    nutritional2: 'Contiene 50% de grasa en forma de manteca, entre 11 y 12% de proteínas, 7 y 8% de almidón, y lo demás está constituido por agua, fibra, ácidos orgánicos esenciales y en menores cantidades tiene azúcar, teobromina, cafeína, y 300 sustancias más.',
    nutritional3: 'El mayor contenido en magnesio se encuentra principalmente en su forma cruda.',
    healthTitle: 'A tu salud',
    health1: 'El poder de sus antioxidantes se asocia con una acción protectora en la prevención y desarrollo de diferentes patologías por estrés oxidativo, tales como; enfermedades cardiovasculares, cerebrovasculares, cáncer hepático, gástrico, de colon, y diabetes.',
    health2: 'Alivia el estrés gracias a la teobromina, que estimula el sistema nervioso central, es más suave que la cafeína, y su efecto es más prolongado.',
    health3: 'El consumo de chocolate negro con 60 o 70% de cacao puede disminuir los niveles de colesterol malo (LDL) y aumenta el bueno (HDL). (Nuestra Marca “Xocolati” tiene 66% de cacao orgánico natural)',
    health4: 'Excelente para el desempeño sexual. Sus químicos como la feniletilamina, anandamida, y la serotonina, son neurotransmisores responsables de estimular en el cerebro las endorfinas encargadas de sensación de bienestar, placer, euforia y excitación.',
    beautyTitle: 'Para tu belleza',
    beauty1: 'No ingerido, sino en forma de productos tópicos, tiene beneficios de hidratación para el cabello y la disminución de la celulitis.',
    beauty2: 'Un gran antioxidante para la piel gracias a sus flavonoides que protegen contra el daño celular que ejercen los radicales libres.',
    beauty3: 'Es un fotoprotector natural, por lo que puede ayudar a evitar el cáncer de piel.',
    eatTitle: 'La mejor forma de comerlo',
    eatDesc: `Según un estudio hecho en Nueva York, se ha demostrado que una taza de Chocolate, es la bebida que contiene mayor concentración de antioxidantes.
    * Tip de las expertas: Consume de 2 a 3 veces por semana de 20 a 30 gramos de chocolate semi-amargo con la mayor cantidad de cacao y menor porción de azúcares y grasas saturadas. (“Xocolati” es la elección)
    La presentación de XOCOLATI es en tablillas de 60 gramos, se puede mezclar con 1 litro de leche light o deslactosada, quedando un toque amargo natural, rico y saludable.`,
  },
  offers: {
    title: 'Ofertas especiales',
    desc: 'Aprovecha las ofertas especiales (Sólo durante la feria del chocolate)',
    offer1: 'Nibs de Cacao Orgánico (ferm. y tostado)',
    offer2: 'Nibs de Cacao con miel virgen (superalimento)',
    offer3: 'Chocolate Semiamargo 70% Tabletas De 60 grs',
    offer4: 'Bites de Chocolates bolsa 70% 40 g',
    offer5: 'Bites de Chocolates bolsa 50% 40 g',
    offer2P1: 'Pieza',
    offer2P2: 'Paquete de 3',
    offer2P3: 'Paquete de 5',
    offer2P4: 'Paquete de 10',
    offer3P1: 'Pieza',
    offer3P2: 'Paquete de 5',
    offer3P3: 'Paquete de 3 Empaques de 5',
    offer3P4: 'Caja de Madera con 6 bolsas de 5',
    offer4P1: 'Pieza',
    offer4P2: 'Paquete de 4',
    offer4P3: 'Paquete de 10',
    offer5P1: 'Pieza',
    offer5P2: 'Paquete de 5',
    offer5P3: 'Paquete de 10',
  },
  catalog: {
    product1: 'Miel natural con nibs de cacao(trocitos de cacao tostado) 20 grs',
    product2: 'Bits de de chocolate al 70% de 40 grs cada una.',
    product3: 'Nibs de cacao(trocitos de cacao tostado) 1 kg, 500 grs, 250 grs.',
    product4: 'Bits de de chocolate al 50% de 40 grs cada una.',
    product5: 'Tabletas de chocolate de mesa semiamargo al 70%  paquete de 5 piezas de 60 grs cada una.',
    product6: 'Tableta o barra  de de chocolate al 50% de 50 grs cada una.',
    product7: 'Granos de Cacao Orgánico fermentado (500 gr)',
    product8: 'Manteca de cacao (500 y 1000gr)',
    product9: 'Mazorcas de Cacao (Temporada)',
    product10: 'Plántulas para siembra',
    product11: 'Chocolate Semiamargo 66% (60, 250 y 500gr)',
  },
};
