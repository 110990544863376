import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.less';
import MainLayout from './componets/mainLayout';
import { Home } from './routes';
import { storeLocalData, getLocalData } from './security';
import i18n from './translations';
import { ConfigProvider } from 'antd';

function App() {
  const [lng, setLng] = useState(getLocalData('lng') || 'es');

  const onLocaleChanged = lng => {
    storeLocalData('lng', lng);
    i18n.changeLanguage(lng).finally(() => setLng(lng));
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: '#6bb66b',
          borderRadius: 10,

          // Alias Token
          colorBgContainer: '#f6ffed',
        },
      }}
    >
      <Routes>
        <Route path='/' element={<MainLayout i18n={i18n} lng={lng} onLocaleChanged={onLocaleChanged} />} >
          <Route
            index
            element={<Home i18n={i18n} />}
          />
        </Route>
      </Routes>
    </ConfigProvider>
  );
}

export default App;
