import React from 'react';
import nutritionalIcon from '../assets/nutritional.svg';
import healthIcon from '../assets/health.svg';
import beautyIcon from '../assets/beauty.svg';
import eatIcon from '../assets/eat.svg';

export const buildInfoData = i18n => [
  {
    icon: nutritionalIcon,
    title: i18n.t('home.nutritionalTitle'),
    description:
      <ol style={{ listStyleType: 'decimal' }} >
        <li>{i18n.t('home.nutritional2')}</li>
        <li>{i18n.t('home.nutritional1')}</li>
        <li>{i18n.t('home.nutritional3')}</li>
      </ol>,
  },
  {
    icon: healthIcon,
    title: i18n.t('home.healthTitle'),
    description:
      <ol style={{ listStyleType: 'decimal' }} >
        <li>{i18n.t('home.health1')}</li>
        <li>{i18n.t('home.health2')}</li>
        <li>{i18n.t('home.health3')}</li>
        <li>{i18n.t('home.health4')}</li>
      </ol>,
  },
  {
    icon: beautyIcon,
    title: i18n.t('home.beautyTitle'),
    description:
      <ol style={{ listStyleType: 'decimal' }} >
        <li>{i18n.t('home.beauty1')}</li>
        <li>{i18n.t('home.beauty2')}</li>
        <li>{i18n.t('home.beauty3')}</li>
      </ol>,
  },
  {
    icon: eatIcon,
    title: i18n.t('home.eatTitle'),
    description:
      <ol>
        <li>{i18n.t('home.eatDesc')}</li>
      </ol>,
  },
];

export const buildDatasource2 = i18n => [
  {
    key: '1',
    name: i18n.t('offers.offer2P1'),
    price: '$12.50 MXN',
  }, {
    key: '2',
    name: i18n.t('offers.offer2P2'),
    price: '$ 30.00 MXN',
  }, {
    key: '3',
    name: i18n.t('offers.offer2P3'),
    price: '$ 50.00 MXN',
  }, {
    key: '4',
    name: i18n.t('offers.offer2P4'),
    price: '$ 100.00 MXN',
  },
];

export const buildDatasource3 = i18n => [
  {
    key: '1',
    name: i18n.t('offers.offer3P1'),
    price: '$15.00 MXN',
  }, {
    key: '2',
    name: i18n.t('offers.offer3P2'),
    price: '$ 75.00 MXN',
  }, {
    key: '3',
    name: i18n.t('offers.offer3P3'),
    price: '$ 190.00 MXN',
  }, {
    key: '4',
    name: i18n.t('offers.offer3P4'),
    price: '$ 400.00 MXN',
  },
];

export const buildDatasource4 = i18n => [
  {
    key: '1',
    name: i18n.t('offers.offer4P1'),
    price: '$15.00 MXN',
  }, {
    key: '2',
    name: i18n.t('offers.offer4P2'),
    price: '$ 50.00 MXN',
  }, {
    key: '3',
    name: i18n.t('offers.offer4P3'),
    price: '$ 120.00 MXN',
  },
];

export const buildDatasource5 = i18n => [
  {
    key: '1',
    name: i18n.t('offers.offer5P1'),
    price: '$12.50 MXN',
  }, {
    key: '2',
    name: i18n.t('offers.offer5P2'),
    price: '$ 50.00 MXN',
  }, {
    key: '3',
    name: i18n.t('offers.offer5P3'),
    price: '$ 100.00 MXN',
  },
];
