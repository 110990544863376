export default {
  home: {
    home: 'Home',
    required: 'Required field',
    name: 'Name',
    phone: 'Phone',
    email: 'e-mail',
    message: 'Message',
    sendedMsg: 'Your message was sended successfully',
    errorMsg: 'Sorry, your message could not be sended',
    send: 'Send',
    more: 'Know more',
    offers: 'Offers',
    artisian: 'Artisian',
    mainMessage: 'Organic chocolate from Tabasco, the best chocolate in Mexico.',
    about: 'About',
    whyUs: 'Why Choose Us?',
    whyUsDesc: 'Cocoa products with unique ecosystem, particular flavor, free of genetic modifications, without the use of fertilizers and pesticides, fermented and sun dried in an artisanal way.',
    aboutTitle: 'Chocolate artisan - xocolati®',
    aboutDesc: 'We are a producer and processor of cocoa derivatives, taking up the best millenary practices in the entire production cycle, contributing to sustainable development, delight, well-being, healthy life, culture and knowledge.',
    productsTitle: 'Product Catalog',
    catalog: 'Catalog',
    contact: 'Contact',
    contactTitle: 'Get In Touch',
    contactDesc: 'Feel free to send us an email.',
    didYouKnow: 'Did you know?',
    infoDesc: `It not for nothing is known as the food of the gods, as the Aztecs and Mayans paid tribute for their great nutritional properties and of course, taste.
    Cocoa seed can be extracted three substances: cocoa liquor, cocoa butter and cocoa powder. They are used to make different products both edible and beauty; couverture chocolate, chocolate desserts, bread, mole, soaps, creams, shampoos, among others.
    According to Sandoval Yareri nutritionist, nutrition coordinator of the Mexican Federation of Diabetes, and the dermatologist Rosa Maria Ponce, this food has multiple benefits:`,
    nutritionalTitle: 'Nutritional properties',
    nutritional1: 'Cocoa tops the list of foods with the highest antioxidant content, almost similar to green tea and red wine. ',
    nutritional2: 'Contains 50% fat in the form of butter, 11 to 12% protein, 7 and 8% starch, and the rest consists of water, fiber, essential organic acids and minor amounts has sugar, theobromine, caffeine, and 300 substances more. ',
    nutritional3: 'The higher content of magnesium is found mainly in its raw form. ',
    healthTitle: 'To your health',
    health1: 'Its antioxidant power is associated with a protective action in the prevention and development of various pathologies by oxidative stress, such as; cardiovascular, cerebrovascular, liver cancer, gastric diseases, colon cancer, and diabetes. ',
    health2: 'Relieves stress thanks to theobromine, which stimulates the central nervous system, it is milder than caffeine and its effect is longer.',
    health3: 'Consumption of black chocolate with 60 to 70% cocoa can reduce levels of bad cholesterol (LDL) and increases good (HDL). (Our Brand "Xocolati" has 66% natural organic cocoa)',
    health4: 'Excellent for sexual performance. Its chemicals like phenylethylamine, anandamide, and serotonin are neurotransmitters responsible for stimulating endorphins in the brain responsible for feeling of well being, pleasure, euphoria and excitement.',
    beautyTitle: 'For your beauty',
    beauty1: 'Not eat, but in the form of topical products has benefits for hair hydration and decreased cellulite.',
    beauty2: 'A great antioxidant for the skin thanks to its flavonoids that protect against cell damage exerted by free radicals.',
    beauty3: 'It is a natural sunscreen, so you can help prevent skin cancer.',
    eatTitle: 'The best way to eat',
    eatDesc: `According to a study done in New York, it has shown that a cup of chocolate, is the drink that contains the highest concentration of antioxidants.
    * Tip skilled: Consumes 2 to 3 times per week for 20 to 30 grams of semisweet chocolate with the most cocoa and lower portion of sugars and saturated fats. ("Xocolati" is the choice)
    The presentation of XOCOLATI in tablets of 60 grams, can be mixed with 1 liter of light or lactose-free milk, leaving a natural, rich and healthy bitter taste.`,
  },
  offers: {
    title: 'Especial offers',
    desc: 'Take advantage of special offers (Only during chocolate fest)',
    offer1: 'Organic Cacao Nibs (fermented and roasted)',
    offer2: 'Cocoa nibs with virgin honey (superfood)',
    offer3: 'Semisweet chocolate 70% 60g Tablets',
    offer4: 'Chocolate Bites 70% 40g Bag',
    offer5: 'Chocolate Bites 50% 40g Bag',
    offer2P1: 'Piece',
    offer2P2: 'Package of 3',
    offer2P3: 'Package of 5',
    offer2P4: 'Package of 10',
    offer3P1: 'Piece',
    offer3P2: 'Packing of 5',
    offer3P3: 'Package of 3 Packing of 5',
    offer3P4: 'Wooden Box with 6 bags of 5',
    offer4P1: 'Piece',
    offer4P2: 'Package of 4',
    offer4P3: 'Package of 10',
    offer5P1: 'Piece',
    offer5P2: 'Package of 5',
    offer5P3: 'Package of 10',
  },
  catalog: {
    product1: 'Natural honey with cocoa seeds(pieces of roasted cocoa) 20g',
    product2: 'Bits of chocolate at 70% of 40g each one.',
    product3: 'Cocoa nibs(pieces of roasted cocoa) 1kg, 500g, 250g.',
    product4: 'Chocolate bits at 50% of 40g each one.',
    product5: 'Semi bitter chocolate tablets at 70% packages of 5 pieces 60g each one.',
    product6: 'Tablet or chocolate bar at 50% of 50g each one.',
    product7: 'Organics Cocoa beans (500g)',
    product8: 'Cocoa butter(500g y 1000g)',
    product9: 'Cocoa Pod (harvest time)',
    product10: 'Seedlings for planting',
  },
};
