import React from 'react';
import { Layout, Menu, ConfigProvider, FloatButton } from 'antd';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import enEN from 'antd/lib/locale/en_US';
import esES from 'antd/lib/locale/es_ES';
import esIcon from '../assets/spanish.svg';
import enIcon from '../assets/english.svg';
import { Outlet } from 'react-router-dom';
import MenuItem from 'antd/lib/menu/MenuItem';

const { Header, Content, Footer } = Layout;
const locales = {
  en: enEN,
  es: esES,
};

const MainLayout = (props) => {
  const { i18n, lng, onLocaleChanged } = props;

  return (
    <ConfigProvider locale={locales[lng]} >
      <Layout>
        <Header style={{
            position: 'fixed', zIndex: 1, width: '100%', padding: '0',
          }}
        >
          <Menu
            theme="light"
            mode="horizontal"
            defaultSelectedKeys={[]}
            style={{ lineHeight: '64px', textAlign: 'center', display: 'block' }}
            selectable={false}
          >
            <MenuItem key="header" ><AnchorLink href="#header" >{i18n.t('home.home')}</AnchorLink></MenuItem>
            <MenuItem key="about" ><AnchorLink href="#about" >{i18n.t('home.artisian')}</AnchorLink></MenuItem>
            <MenuItem key="catalog" ><AnchorLink href="#catalog" >{i18n.t('home.catalog')}</AnchorLink></MenuItem>
            <MenuItem key="contact" ><AnchorLink href="#contact" >{i18n.t('home.contact')}</AnchorLink></MenuItem>
            <MenuItem key="es" onClick={() => onLocaleChanged('es')} >
              <img alt="" src={esIcon} style={{ width: '60%' }} />
            </MenuItem>
            <MenuItem key="en" onClick={() => onLocaleChanged('en')} >
              <img alt="" src={enIcon} style={{ width: '60%' }} />
            </MenuItem>
          </Menu>
        </Header>
        <Content>
          <div style={{ minHeight: '100vh' }}>
            <FloatButton.BackTop />
            <Outlet />
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          ©{new Date().getFullYear()} Xocolati
        </Footer>
      </Layout>
    </ConfigProvider>
  );
};

export default MainLayout;
